import { UserService } from '@features/auth';
import { CPQ_ROLES } from '../roles';
import { FilterableMenuItem } from './model';

export const lostOrderReporting: FilterableMenuItem = {
  title: 'reporting.lostOrderReporting',
  icon: 'list',
  routerLink: '/reporting',
};

export const reportingMenu: FilterableMenuItem = {
  title: 'reporting.reporting',
  icon: 'bar_chart',
  routerLink: '/reporting',
  data: { id: 'reporting' },

  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_REPORTING) &&
    userService.hasRole(CPQ_ROLES.CPQ_EARLY_ACCESS),

  children: [lostOrderReporting],
};
